import { Box, Grid, useColorModeValue, VStack } from '@chakra-ui/react';
import { animated, easings, useSpring, useTransition } from '@react-spring/web';
import { useCallback, useEffect, useRef, useState } from 'react';
import { ColorModeSwitcher } from './ColorModeSwitcher';

import './style.scss';

export const App = () => {
  const [state, setState] = useState(false);

  const aRef = useRef(null);
  const bRef = useRef(null);

  const nodeRef = state ? aRef : bRef;
  const text = state ? 'græfik' : 'græphik'; // æÆ ALT + 145, 146, 0230

  useEffect(() => {
    const ref = setInterval(() => {
      setState((state) => !state);
    }, 2000);
    return () => {
      clearInterval(ref);
    };
  }, [setState]);

  const { x } = useSpring({
    from: { x: 0 },
    x: state ? 1 : 0,
    config: { duration: 2000 },
  });

  return (
    <Box textAlign="center" bg={useColorModeValue('gray.100', 'gray.800')}>
      <Grid minH="100vh" p={3}>
        <ColorModeSwitcher justifySelf="flex-end" />
        <VStack spacing={8}>
          <animated.div
            className="header"
            style={{
              opacity: x.to({ range: [0, 0.1, 0.9, 1], output: [0, 1, 1, 0] }),
              // scale: x.to({
              //   range: [0, 0.25, 0.35, 0.45, 0.55, 0.65, 0.75, 1],
              //   output: [1, 0.97, 0.9, 1.1, 0.9, 1.1, 1.03, 1],
              // }),
            }}
          >
            {text}
          </animated.div>
        </VStack>
      </Grid>
    </Box>
  );
};
